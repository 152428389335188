import React, {useState} from 'react';
import styled from 'styled-components';
import {graphql, Link, useStaticQuery} from "gatsby";
import linkResolver from "../../utils/linkResolver";

const Holder = styled.ul`
  display: none;
  @media( ${props => props.theme.breakpoints.md} ) {
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    margin: 0;
  }
  > li {
    margin: 0 0 0 4rem;
    position: relative;
    a {
      text-decoration: none;
    }
  }
`;

const Dropdown = styled.div`
  position: absolute;
  top: calc(100% + 12px);
  left: -24px;
  background-color: ${props => props.theme.colors.yellow};
  ul {
    list-style: none;
    padding: 12px 0;
    font-size: 14px;
    margin: 0;
    li {
      padding: 0;
      margin: 0;
      a {
        display: block;
        padding: 7px 24px;
        white-space: nowrap;
        &:hover {
          color: ${props => props.theme.colors.yellow};
          background-color: ${props => props.theme.colors.black};
        }
      }
    }
  }
`;

function LargeNav() {
  const [ openServices, setOpenServices ] = useState( false );
  const data = useStaticQuery(graphql`
        query LargeNavQuery {
            prismicMenus {
                data {
                    services_menu {
                        link {
                            type
                            uid
                            document {
                                ... on PrismicPage {
                                    id
                                    data {
                                        title {
                                            text
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }   
        }
  `)
  const {services_menu} = data.prismicMenus.data;
  return (
    <Holder>
      <li>
        <button className="link" onMouseEnter={() => setOpenServices(true)}>Services</button>
        {openServices && <Dropdown onMouseLeave={() => setOpenServices(false)} className="dropdown-menu">
          <ul className="mono">
            {services_menu.map((item, i) =>
              <li key={i}><Link to={linkResolver(item.link)} onClick={() => setOpenServices(false)}>{item.link.document.data.title.text}</Link></li>
            )}
          </ul>
        </Dropdown>}
      </li>
      <li><Link to="/about">About</Link></li>
      <li><Link to="/contact" className="button contact-button">Contact</Link></li>
    </Holder>
  )
}

export default LargeNav;