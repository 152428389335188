import * as React from 'react'
import {graphql} from 'gatsby'
import {SliceZone} from '@prismicio/react'
import {scroller} from "react-scroll";


import {components} from '../components/slices'
import Seo from "../components/molecules/Seo"
import {withPrismicPreview} from "gatsby-plugin-prismic-previews";
import {useEffect} from "react";

const PageTemplate = ({data, location}) => {
  let scrollToId = null;
  if (location.state) {
    scrollToId = location.state.scrollToId;
  }

  useEffect(() => {
    if (scrollToId) {
      scroller.scrollTo(scrollToId, {
        duration: 750,
        smooth: true,
        offset: -96
      });
    }
  }, [scrollToId]);

  if (!data) return null
  const doc = data.prismicPage.data

  return (
    <>
      <Seo title={doc.title.text} />
      <SliceZone slices={doc.body} components={components} />
    </>
  )
}

export const query = graphql`
    query PageQuery($id: String) {
        prismicPage(id: { eq: $id }) {
            _previewable
            data {
                title {
                    text
                }
                body {
                    ... on PrismicSliceType {
                        slice_type
                    }
                    ...PageDataBodyCta
                    ...PageDataBodyFeature
                    ...PageDataBodyHero
                    ...PageDataBodyServiceHero
                    ...PageDataBodyTeam
                    ...PageDataBodyValues
                }
            }
        }
    }
`

export default withPrismicPreview(PageTemplate)