import React, {useState} from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import {graphql, Link} from "gatsby";
import Rarr from '../../assets/svg/rarr.inline.svg';
import Plus from '../../assets/svg/expand.inline.svg';
import Container from "../atoms/Container";
import classNames from "classnames";
import {GatsbyImage} from "gatsby-plugin-image";
import {PrismicRichText} from "@prismicio/react";
import linkResolver from '../../utils/linkResolver';
import AnimateOn from "../atoms/AnimateOn";

const Holder = styled.div`
  margin: 44px 0;
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin: 96px 0;
  }
`;

const ImageHolder = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 80%;
  position: relative;
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding-bottom: 150%;
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    padding-bottom: 100%;
  }

  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const TextHolder = styled.div`
  padding: 32px;
  @media ( ${props => props.theme.breakpoints.md} ) {
    display: flex;
    flex-direction: column;
    padding: 32px 44px 44px;
  }
  @media ( ${props => props.theme.breakpoints.xl} ) {
    padding: 72px 92px 92px;
  }

  > :first-child { margin-top: 0; }

  > :last-child { margin-bottom: 0; }

  .button-holder {
    margin-top: 40px;
  }

  .h2 {
    @media ( ${props => props.theme.breakpoints.md} ) {
      margin-top: auto;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 8px;
  transform: translateZ(0);
  border: 1px solid;
  position: relative;
  overflow: hidden;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr;
    &.switch {
      ${ImageHolder} {
        order: 2;
      }

      ${TextHolder} {
        order: 1;
      }
    }
  }
`;

function SliceFeature({slice}) {
  const [showExtra, setShowExtra] = useState(false);
  const {image, link, strapline, switch_order, text, extra_text, title1} = slice.primary;
  const anchorId = slice.primary['id'];
  const switchClass = classNames({'switch': switch_order})
  return (
    <Holder id={anchorId && anchorId.text}>
      <Container>
        <AnimateOn>
          <Grid className={switchClass}>
            <ImageHolder>
              <GatsbyImage alt={image.alt || ""} image={image.gatsbyImageData} />
            </ImageHolder>
            <TextHolder>
              <h2 className="subhead">{title1.text}</h2>
              <p className="h2">{strapline.text}</p>
              <PrismicRichText field={text.richText} />
              {extra_text.richText.length > 0 &&
                <>
                  {!showExtra && <p className="button-holder">
                    <button className="button with-icon" onClick={() => setShowExtra(true)}>Read more <Plus /></button>
                  </p>}
                  {showExtra && <PrismicRichText field={extra_text.richText} />}
                </>
              }
              {((extra_text.richText.length === 0 && link.document) || (extra_text.richText.length > 0 && showExtra && link.document)) &&
                <p className="button-holder"><Link className="button with-icon" to={linkResolver(link.document)}>Learn More <Rarr /></Link></p>}
            </TextHolder>
          </Grid>
        </AnimateOn>
      </Container>
    </Holder>
  )
}

SliceFeature.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default SliceFeature;

export const query = graphql`
    fragment HomepageDataBodyFeature on PrismicHomepageDataBodyFeature {
        primary {
            id {
                text
            }
            image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
            link {
                document {
                    ... on PrismicPage {
                        id
                        uid
                        type
                    }
                    ... on PrismicHomepage {
                        id
                        type
                    }
                }
            }
            strapline {
                text
            }
            switch_order
            text {
                richText
            }
            extra_text {
                richText
            }
            title1 {
                text
            }
        }
    }
    fragment PageDataBodyFeature on PrismicPageDataBodyFeature {
        primary {
            id {
                text
            }
            image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
            link {
                document {
                    ... on PrismicPage {
                        id
                        uid
                        type
                    }
                    ... on PrismicHomepage {
                        id
                        type
                    }
                }
            }
            strapline {
                text
            }
            switch_order
            text {
                richText
            }
            extra_text {
                richText
            }
            title1 {
                text
            }
        }
    }
`