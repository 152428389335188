import React, {useEffect} from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import useScrollTrigger from "../../hooks/useScrollTrigger";

const Holder = styled.div`
  width: 32px;
  height: 32px;
  position: relative;
`;

const Marker = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid;
  border-radius: 50%;
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.yellow};
  position: relative;
  z-index: 2;

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.black};
  }
`;

const Pulse = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.yellow};
  z-index: 1;
  opacity: 0.6;
`;

function MapMarker({lat, lng}) {
  const {tl, holderRef, gsap, q} = useScrollTrigger();

  useEffect(() => {
    if (!tl.current) {
      tl.current = gsap.timeline({
        repeat: -1
      }).to(q(`.pulse`), {
        scale: 3,
        autoAlpha: 0,
        rotation: 1,
        duration: 4,
        stagger: 2,
        ease: 'none'
      })
    }
  })

  return (
    <Holder
      ref={holderRef}
      lat={lat}
      lng={lng}>
      <Marker />
      <Pulse className="pulse" />
      <Pulse className="pulse" />
      <Pulse className="pulse" />
    </Holder>
  )
}

MapMarker.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};

export default MapMarker;