import React from "react";
import Seo from "../components/molecules/Seo";
import styled from "styled-components";
import {SliceZone} from '@prismicio/react'
import {components} from '../components/slices'
import {graphql} from "gatsby";
import {withPrismicPreview} from "gatsby-plugin-prismic-previews";

const Holder = styled.div`
`;

function IndexPage ({data}) {
    return (
      <Holder>
          <Seo title="Smart, automated warehousing and sorting solutions." />
          <SliceZone slices={data.prismicHomepage.data.body} components={components} />
      </Holder>
    )
};

export default withPrismicPreview(IndexPage);

export const homePageQuery = graphql`
    query {
        prismicHomepage {
            _previewable
            data {
                body {
                    ... on PrismicSliceType {
                        slice_type
                    }
                    ...HomepageDataBodyCta
                    ...HomepageDataBodyFeature
                    ...HomepageDataBodyHero
                    ...HomepageDataBodyValues
                }
            }
        }
    }
`;