import React from 'react';
import GoogleMapReact from 'google-map-react';
import styled from "styled-components";
import Container from "../atoms/Container";
import MapMarker from "../atoms/MapMarker";


const Holder = styled.div`
  border-radius: 8px;
  transform: translateZ(0);
  overflow: hidden;
  margin-bottom: 32px;
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin-bottom: 82px;
  }
`;

function Map() {

  const defaultProps = {
    center: {
      lat: -37.818536768856184,
      lng: 144.9791830274568
    },
    zoom: 10,
    style: [
      {
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "saturation": 36
          },
          {
            "color": "#000000"
          },
          {
            "lightness": 40
          }
        ]
      },
      {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "visibility": "on"
          },
          {
            "color": "#000000"
          },
          {
            "lightness": 16
          }
        ]
      },
      {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#000000"
          },
          {
            "lightness": 20
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#000000"
          },
          {
            "lightness": 17
          },
          {
            "weight": 1.2
          }
        ]
      },
      {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#000000"
          },
          {
            "lightness": 20
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#000000"
          },
          {
            "lightness": 21
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#000000"
          },
          {
            "lightness": 17
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#000000"
          },
          {
            "lightness": 29
          },
          {
            "weight": 0.2
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#000000"
          },
          {
            "lightness": 18
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#000000"
          },
          {
            "lightness": 16
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#000000"
          },
          {
            "lightness": 19
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#000000"
          },
          {
            "lightness": 17
          }
        ]
      }
    ]
  };

  return (
    // Important! Always set the container height explicitly
    <Container>
      <Holder>
        <div style={{height: '400px', width: '100%'}}>
          <GoogleMapReact
            bootstrapURLKeys={{key: process.env.GATSBY_GOOGLE_API_KEY}}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            options={{styles: defaultProps.style}}
          >
            <MapMarker lng={defaultProps.center.lng} lat={defaultProps.center.lat}/>
          </GoogleMapReact>
        </div>
      </Holder>
    </Container>
  );
}

export default Map;