import React from "react";
import PropTypes from "prop-types";
import '../utils/fontface.css';

import Header from "../components/molecules/Header";
import Footer from "../components/molecules/Footer";

import GlobalStyles from "../components/atoms/GlobalStyles";
import styled, {ThemeProvider} from "styled-components";
import {theme} from "../utils/styling";

const Main = styled.main`
`;

function Index({location, children}) {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <Header light={location.pathname === '/contact'} />
        <Main>{children}</Main>
        <Footer />
      </>
    </ThemeProvider>
  );
}

Index.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Index;
