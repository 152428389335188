import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import {graphql, Link} from "gatsby";
import Rarr from '../../assets/svg/rarr.inline.svg';
import {GatsbyImage} from "gatsby-plugin-image";
import {PrismicRichText} from '@prismicio/react'
import SvgLine from "../organisms/SvgLine";
import DotGrid from "../organisms/DotGrid";
import Container from "../atoms/Container";
import AnimateOn from "../atoms/AnimateOn";

const Holder = styled.div`
  margin: 32px 0 0 0;
  overflow: hidden;
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin: 96px 0 0 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-gap: 44px;
    position: relative;
  }

  .dot-grid {
    margin-top: calc((100vw - 60px) / 7 - 32px);
    @media ( ${props => props.theme.breakpoints.md} ) {
      margin-top: calc((100vw - 120px) / 14 - 44px);
    }
  }
`;

const ImageHolder = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 80%;
  position: relative;
  border-radius: 8px;
  transform: translateZ(0);
  border: 1px solid #666666;
  overflow: hidden;
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding-bottom: 50%;
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    padding-bottom: 29%;
  }

  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const TextHolder = styled.div`
  @media ( ${props => props.theme.breakpoints.md} ) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    p {
      margin: 0;
    }
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-template-columns: 6fr 3fr 2fr;
    grid-gap: 0;
  }
  @media ( ${props => props.theme.breakpoints.xl} ) {
    grid-template-columns: 9fr 3fr 2fr;
    grid-gap: 0;
    h2 { width: 66.6667%; }
  }

  h2 {
    ${(props) => props.theme.fluidType(24, 44)};
  }

  > :first-child { margin-top: 0; }

  > :last-child { margin-bottom: 0; }

  .button-holder {
    margin-top: 32px;
    @media ( ${props => props.theme.breakpoints.md} ) {
      margin: 0;
      text-align: right;
      grid-column: 2/3;
    }
    @media ( ${props => props.theme.breakpoints.lg} ) {
      grid-column: auto;
    }

    .button {
      width: 100%;
      justify-content: space-between;
      @media ( ${props => props.theme.breakpoints.md} ) {
        width: auto;
      }
    }
  }
`;

function SliceCTA({slice}) {
  const {image, text, title1} = slice.primary;
  return (
    <Holder className="slice-cta">
      <Container>
        <AnimateOn>
          <Grid>
            <ImageHolder>
              <GatsbyImage alt={image.alt || title1.text} image={image.gatsbyImageData} />
            </ImageHolder>
            <TextHolder>
              <h2>{title1.text}</h2>
              <PrismicRichText field={text.richText} />
              <p className="button-holder"><Link className="button with-icon inverse" to="/contact">Get Started <Rarr /></Link>
              </p>
            </TextHolder>
            <DotGrid
              className="mobile-grid"
              columns={7}
              rows={2}>
              <SvgLine
                start={[1, 0]}
                midpoints={[
                  [7, 0],
                ]}
                end={[7, 2]} />
            </DotGrid>
            <DotGrid
              className="desktop-grid"
              columns={14}
              rows={2}>
              <SvgLine
                start={[14, 1]}
                midpoints={[
                  [12, 1],
                  [12, 0],
                  [9, 0],
                ]}
                end={[9, 3]}
                hideEnd={true}
                twoArrows />
            </DotGrid>
            <DotGrid
              className="desktop-left bottom"
              columns={14}
              rows={2} />
            <DotGrid
              className="desktop-right bottom"
              columns={14}
              rows={2} />
          </Grid>
        </AnimateOn>
      </Container>
    </Holder>
  )
}

SliceCTA.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default SliceCTA;

export const query = graphql`
    fragment HomepageDataBodyCta on PrismicHomepageDataBodyCta {
        primary {
            image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
            text {
                richText
            }
            title1 {
                text
            }
        }
    }
    fragment PageDataBodyCta on PrismicPageDataBodyCta {
        primary {
            image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
            text {
                richText
            }
            title1 {
                text
            }
        }
    }
`