import React from "react";
import Seo from "../components/molecules/Seo";
import styled from "styled-components";
import Map from "../components/organisms/Map";
import ContactDetails from "../components/organisms/ContactDetails";
import ContactFormHolder from "../components/organisms/ContactFormHolder";
import {graphql} from "gatsby";
import {withPrismicPreview} from "gatsby-plugin-prismic-previews";

const Holder = styled.div`
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.darkGrey};
  padding-top: 96px;
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding-top: 132px;
  }
`;

function ContactPage({data}) {
  const {
    address,
    contact_form_text,
    email,
    facebook,
    instagram,
    linkedin,
    phone,
    twitter
  } = data.prismicContact.data;
  return (
    <Holder>
      <Seo title="Get in touch" />
      <Map />
      <ContactDetails
        address={address}
        phone={phone.text}
        email={email.text}
        twitterUrl={twitter.url}
        instagramUrl={instagram.url}
        facebookUrl={facebook.url}
        linkedinUrl={linkedin.url}
      />
      <ContactFormHolder text={contact_form_text}/>
    </Holder>
  )
}

export default withPrismicPreview(ContactPage);

export const contactPageQuery = graphql`
    query {
        prismicContact {
            _previewable
            data {
                address {
                    richText
                }
                contact_form_text {
                    richText
                }
                email {
                    text
                }
                facebook {
                    url
                }
                instagram {
                    url
                }
                linkedin {
                    url
                }
                phone {
                    text
                }
                twitter {
                    url
                }
            }
        }
    }
`;