import React from "react"
import styled from "styled-components";
import {graphql, Link, useStaticQuery} from "gatsby";
import Logo from '../../assets/svg/logo-symbol.inline.svg';
import Insta from '../../assets/svg/instagram.inline.svg';
import Fb from '../../assets/svg/fb.inline.svg';
import Twitter from '../../assets/svg/twitter.inline.svg';
import LinkedIn from '../../assets/svg/linkedin.inline.svg';
import linkResolver from '../../utils/linkResolver';
import {formatPhoneString} from "../../utils/helpers";
import Container from "../atoms/Container";

const Holder = styled.footer`
  background-color: ${props => props.theme.colors.yellow};

  a {
    text-decoration: none;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-row-gap: 32px;
  padding-top: 32px;
  padding-bottom: 32px;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: repeat(14, 1fr);
    grid-auto-flow: dense;
    grid-row-gap: 0;
    padding-top: 0;
    padding-bottom: 66px;
  }
`;

const LogoHolder = styled.div`
  grid-column: span 2;
  border-bottom: 2px solid;
  @media ( ${props => props.theme.breakpoints.md} ) {
    border-bottom: none;
    padding-top: 32px;
  }

  svg {
    width: 38px;
    height: auto;
    display: block;
  }
`;

const ListHolder = styled.div`
  padding-bottom: 32px;
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding-top: 32px;
  }

  h3 {
    font-size: 16px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 16px;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;

    li {
      font-family: 'ApproachMono', monospace;
      font-size: 12px;
      margin: 8px 0;
    }
  }

  &.contact {
    grid-column: span 5;
    padding-bottom: 32px;
    border-bottom: 2px solid;
    @media ( ${props => props.theme.breakpoints.md} ) {
      grid-column: 12/15;
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  &.services {
    grid-column: span 5;
    @media ( ${props => props.theme.breakpoints.md} ) {
      grid-column: 3/7;
    }
  }

  &.about {
    grid-column: span 2;
    @media ( ${props => props.theme.breakpoints.md} ) {
      grid-column: 7/10;
      border-right: 2px solid;
    }
  }
`;

const Copyright = styled.div`
  padding-top: 32px;
  border-top: 2px solid;
  grid-column: span 7;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-column: span 14;
    display: flex;
    justify-content: space-between;
  }

  p {
    margin: 0;
    font-family: 'ApproachMono', monospace;
    font-size: 12px;
  }
`;


const Socials = styled.ul`
  display: flex;
  padding-top: 8px;

  li {
    margin: 0;
  }

  svg {
    height: 32px;
    width: auto;
    display: block;
    margin-right: 16px;

    rect {
      display: none;
    }
  }
`;

function Footer() {

  const data = useStaticQuery(graphql`
      query FooterQuery {
          prismicMenus {
              data {
                  services_menu {
                      link {
                          type
                          uid
                          document {
                              ... on PrismicPage {
                                  id
                                  data {
                                      title {
                                          text
                                      }
                                  }
                              }
                          }
                      }
                  }
                  about_menu {
                      section_id {
                          text
                      }
                      section_title {
                          text
                      }
                  }
              }
          }
          prismicContact {
              data {
                  email {
                      text
                  }
                  facebook {
                      url
                  }
                  instagram {
                      url
                  }
                  linkedin {
                      url
                  }
                  phone {
                      text
                  }
                  twitter {
                      url
                  }
              }
          }
      }
  `)

  const {services_menu, about_menu} = data.prismicMenus.data;
  const {email, phone, facebook, instagram, linkedin, twitter} = data.prismicContact.data;

  return (
    <Holder>
      <Container>
        <Grid>
          <LogoHolder>
            <Logo />
          </LogoHolder>
          <ListHolder className="contact">
            <h3>Contact</h3>
            <ul>
              <li><a href={`mailto:${email.text}`}>info@primarysight.com.au</a></li>
              <li><a href={`tel:${formatPhoneString(phone.text)}`}>{phone.text}</a></li>
            </ul>
            <Socials>
              <li><a target="_blank" rel="noopener noreferrer" href={instagram.url}><Insta /></a></li>
              <li><a target="_blank" rel="noopener noreferrer" href={twitter.url}><Twitter /></a></li>
              <li><a target="_blank" rel="noopener noreferrer" href={facebook.url}><Fb /></a></li>
              <li><a target="_blank" rel="noopener noreferrer" href={linkedin.url}><LinkedIn /></a></li>
            </Socials>
          </ListHolder>
          <ListHolder className="services">
            <h3>Services</h3>
            <ul>
              {services_menu.map((item, i) =>
                <li key={i}><Link to={linkResolver(item.link)}>{item.link.document.data.title.text}</Link></li>
              )}
            </ul>
          </ListHolder>
          <ListHolder className="about">
            <h3>About</h3>
            <ul>
              {about_menu.map((item, i) =>
                <li key={i}><Link to={`/about`} state={{scrollToId: item.section_id.text}}>{item.section_title.text}</Link></li>
              )}
            </ul>
          </ListHolder>
          <Copyright>
            <p>© {new Date().getFullYear()},{" "} Primary Sight. <Link to="/privacy">Privacy Policy</Link></p>
            <p>Site by <a href="https://atollon.com.au/" target="_blank" rel="noopener noreferrer">Atollon</a></p>
          </Copyright>
        </Grid>
      </Container>
    </Holder>
  )
}

export default Footer
