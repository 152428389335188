import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import classNames from "classnames";

const dotWidth = 4;

const Square = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  z-index: 2;

  &:before,
  &:after {
    content: "";
    width: ${dotWidth}px;
    height: ${dotWidth}px;
    border-radius: 50%;
    position: absolute;
    top: -${dotWidth / 2}px;
  }

  &:before { left: -${dotWidth / 2}px; }

  &:after {
    right: -${dotWidth / 2}px;
    display: none;
  }
`;

const Holder = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  position: relative;

  ${Square} {
    &:before,
    &:after {
      background-color: ${props => props.theme.colors[props.dotColor]};
    }

    &:nth-child(${props => props.columns}n) {
      &:after {
        display: block;
      }
    }
  }

  &.mobile-grid {
    margin: 0 2px;
    @media ( ${props => props.theme.breakpoints.md} ) {
      display: none;
    }
  }

  &.desktop-grid {
    display: none;
    @media ( ${props => props.theme.breakpoints.md} ) {
      display: grid;
    }
  }
  &.desktop-left,
  &.desktop-right {
    display: none;
    @media ( min-width: ${(props) => props.theme.typography.maxScreen}px ) {
      display: grid;
      position: absolute;
      z-index: 1;
      width: 100vw;
      max-width: calc(${(props) => props.theme.typography.maxScreen}px - 120px);
      &.bottom {
        bottom: 0;
      }
      &.top {
        top: 0;
      }
    }
  }
  &.desktop-left {
    @media ( ${props => props.theme.breakpoints.md} ) {
      right: 100%;
    }
  }
  &.desktop-right {
    @media ( ${props => props.theme.breakpoints.md} ) {
      left: 100%;
    }
  }

  svg {
    path {
      stroke: ${props => props.theme.colors[props.lineColor]};
    }
  }

  .circle {
    border: 2px solid ${props => props.theme.colors[props.lineColor]};

    .inner {
      background-color: ${props => props.theme.colors[props.dotColor]};
    }
  }
`;

function DotGrid({columns, rows, light, className, children, lineColor, dotColor}) {
  const holderClasses = classNames(
    'dot-grid',
    className,
    {light}
  )

  let cols = [];
  for (let i = 0; i < columns; i++) {
    cols.push(<Square className="dot-grid-square" key={i} />);
  }

  let gridRows = [];
  for (let i = 0; i < rows + 1; i++) {
    gridRows.push(<React.Fragment key={i}>{cols}</React.Fragment>);
  }

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        columns: columns,
        rows: rows,
      });
    }
    return child;
  });

  return (
    <Holder
      className={holderClasses}
      columns={columns}
      dotColor={dotColor}
      lineColor={lineColor}>
      {gridRows}
      {childrenWithProps}
    </Holder>
  )
}

DotGrid.propTypes = {
  columns: PropTypes.number.isRequired,
  rows: PropTypes.number,
  className: PropTypes.string,
  light: PropTypes.bool,
  lineColor: PropTypes.string,
  dotColor: PropTypes.string,
};

DotGrid.defaultProps = {
  light: false,
  lineColor: 'yellow',
  dotColor: 'darkGrey',
};

export default DotGrid;