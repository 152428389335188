import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import {graphql} from "gatsby";
import AccordionGroup from "../atoms/AccordionGroup";
import Accordion from "../atoms/Accordion";
import {GatsbyImage} from "gatsby-plugin-image";
import {PrismicRichText} from "@prismicio/react";
import DotGrid from "../organisms/DotGrid";
import SvgLine from "../organisms/SvgLine";
import Container from "../atoms/Container";

const HolderFirst = styled.div`
  padding-top: 96px;
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding-top: 132px;
  }
`;
const HolderSecond = styled.div`
  overflow: hidden;

  + .slice-cta {
    margin-top: 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
    position: relative;
    padding-bottom: calc((100vw - 120px) / 14 * 3.75);
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-template-columns: 5fr 2fr 6fr 1fr;
    grid-column-gap: 0;
  }

  .mobile-grid {
    margin-top: calc((100vw - 60px) / 7);
  }

  .desktop-grid {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    bottom: 0;
  }
`;

const ImageHolder = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 80%;
  position: relative;
  z-index: 5;
  border-radius: 8px;
  transform: translateZ(0);
  border: 1px solid #666666;
  overflow: hidden;
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding-bottom: 40%;
    grid-column: span 2;
    background-color: ${props => props.theme.colors.white};
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-column: span 4;
  }

  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const TextHolder = styled.div`
  margin-top: 24px;
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin-top: 0;
  }

  > :first-child { margin-top: 0; }

  > :last-child { margin-bottom: 0; }
`;

const Spacer = styled.div`
  display: none;
  @media ( ${props => props.theme.breakpoints.md} ) {
    display: block;
    grid-column: span 2;
    height: 44px;
    ${props => props.theme.colors.white};
  }
  @media ( ${props => props.theme.breakpoints.md} ) {
    height: 68px;
    grid-column: span 2;
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-column: span 4;
  }
`;

const ListHolder = styled.div`
  margin-bottom: 24px;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    margin-bottom: 0;
    grid-column: 3/4;
  }

  > :first-child { margin-top: 0; }

  > :last-child { margin-bottom: 0; }
`;

function SliceServiceHero({slice}) {
  const {text, image, title1, video} = slice.primary;
  const {items} = slice;
  return (
    <>
      <HolderFirst>
        <Container>
          <ImageHolder>
            {!video.url && <GatsbyImage alt={image.alt || ""} image={image.gatsbyImageData} />}
            {video.url && <video autoPlay muted playsInline loop poster={image ? image.url : ""}>
              <source src={video.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>}
          </ImageHolder>
        </Container>
      </HolderFirst>
      <HolderSecond>
        <Container>
          <Grid>
            <Spacer className="add-bg" />
            <TextHolder className="add-bg">
              <h1>{title1.text}</h1>
              <PrismicRichText field={text.richText} />
            </TextHolder>
            <DotGrid
              className="mobile-grid"
              columns={7}
              rows={1}>
            </DotGrid>
            <ListHolder className="add-bg">
              {items.length > 0 && <AccordionGroup>
                {items.map((item, i) => (
                  <Accordion key={i} title={item.list_item.text} scrollTo={false}>
                    {item.list_item_details.richText.length > 0 &&
                      <PrismicRichText field={item.list_item_details.richText} />}
                  </Accordion>
                ))}
              </AccordionGroup>}
            </ListHolder>
            <DotGrid
              className="mobile-grid"
              columns={7}
              rows={4}>
              <SvgLine
                start={[0, 0]}
                midpoints={[
                  [0, 2],
                ]}
                end={[4, 2]} />
              <SvgLine
                start={[7, 1]}
                midpoints={[
                  [7, 3],
                  [1, 3],
                ]}
                end={[1, 4]} />
            </DotGrid>
            <DotGrid
              className="desktop-grid"
              columns={14}
              rows={10}>
              <SvgLine
                start={[1, 8]}
                midpoints={[
                  [1, 9],
                  [8, 9],
                ]}
                end={[8, 11]}
                hideEnd={true}
                twoArrows/>
            </DotGrid>
            <DotGrid
              className="desktop-left bottom"
              columns={14}
              rows={10} />
            <DotGrid
              className="desktop-right bottom"
              columns={14}
              rows={10} />
          </Grid>
        </Container>
      </HolderSecond>
    </>
  )
}

SliceServiceHero.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default SliceServiceHero;

export const query = graphql`
    fragment PageDataBodyServiceHero on PrismicPageDataBodyServiceHero {
        primary {
            image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                url(imgixParams: {width: 1500})
            }
            video {
                url
            }
            title1 {
                text
            }
            text {
                richText
            }
        }
        items {
            list_item {
                text
            }
            list_item_details {
                richText
            }
        }
    }
`