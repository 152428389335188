import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import {graphql} from "gatsby";
import {PrismicRichText} from "@prismicio/react";
import SvgLine from "../organisms/SvgLine";
import DotGrid from "../organisms/DotGrid";
import {GatsbyImage} from "gatsby-plugin-image";
import Container from "../atoms/Container";
import AnimateOn from "../atoms/AnimateOn";
import Value from "../molecules/Value";

const Holder = styled.div`
  overflow: hidden;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.darkGrey};
`;

const Grid = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: repeat(7, 1fr);
  padding-top: calc((100vw - 60px) / 7);
  grid-gap: 0;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: repeat(14, 1fr);
    padding: calc((100vw - 120px) / 14 * 1.75) 0;
    grid-row-gap: calc((100vw - 120px) / 14 * 3.5);
    align-items: start;
  }

  .mobile-grid {
    grid-column: span 7;
  }

  .desktop-grid {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 0;
  }

  .desktop-grid,
  .desktop-left,
  .desktop-right {
    .dot-grid-square {
      &:nth-child(-n+14) {
        &:before,
        &:after {
          opacity: 0;
        }
      }
    }
  }
`;

const Intro = styled.div`
  grid-column: span 7;
  margin-bottom: calc((100vw - 60px) / 7);
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-column: span 5;
    margin-bottom: 0;
  }

  > :first-child { margin-top: 0; }

  > :last-child { margin-bottom: 0; }
`;


function SliceTeam({slice}) {
  const {title1} = slice.primary;
  return (
    <Holder>
      <Container>
        <Grid>
          <DotGrid
            className="mobile-grid"
            columns={7}
            rows={1}
            lineColor="yellow"
            dotColor="white">
          </DotGrid>
          {title1.text && <Intro className="add-bg bg-darkGrey">
            <h2 className="h1">{title1.text}</h2>
          </Intro>}
          <DotGrid
            className="mobile-grid"
            columns={7}
            rows={2}
            lineColor="yellow"
            dotColor="white">
            <SvgLine
              start={[7, 0]}
              midpoints={[
                [7, 1],
                [2, 1],
              ]}
              end={[2, 2]} />
          </DotGrid>
          {slice.items.length > 0 && slice.items.map((item, i) =>
            <React.Fragment key={i}>
              <Value item={item} i={i}/>
              {i === 1 && <DotGrid
                className="mobile-grid"
                columns={7}
                rows={8}
                lineColor="yellow"
                dotColor="white">
                <SvgLine
                  start={[3, 1]}
                  midpoints={[
                    [3, 2],
                    [0, 2],
                    [0, 5],
                    [3, 5],
                  ]}
                  end={[3, 7]} />
                <SvgLine
                  start={[7, 1]}
                  midpoints={[
                    [7, 3],
                    [4, 3],
                  ]}
                  end={[4, 6]} />
              </DotGrid>}
            </React.Fragment>
          )}
          <DotGrid
            className="mobile-grid"
            columns={7}
            rows={1}
            lineColor="yellow"
            dotColor="white">
          </DotGrid>
          <DotGrid
            className="desktop-grid"
            columns={14}
            rows={20}
            lineColor="yellow"
            dotColor="white">
            <SvgLine
              start={[6, 0]}
              midpoints={[
                [6, 7],
                [11, 7],
                [11, 6],
              ]}
              end={[14, 6]}
              hideStart
              twoArrows />
            <SvgLine
              start={[3, 5]}
              midpoints={[
                [3, 7],
                [5, 7],
                [5, 10],
                [6, 10],
              ]}
              end={[6, 20]}
              hideEnd
              twoArrows />
            <SvgLine
              start={[0, 10]}
              end={[0, 20]}
              hideEnd />
          </DotGrid>
          <DotGrid
            className="desktop-left top"
            columns={14}
            rows={20}
            lineColor="yellow"
            dotColor="white" />
          <DotGrid
            className="desktop-right top"
            columns={14}
            rows={20}
            lineColor="yellow"
            dotColor="white" />
        </Grid>
      </Container>
    </Holder>
  )
}

SliceTeam.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default SliceTeam;

export const query = graphql`
    fragment PageDataBodyValues on PrismicPageDataBodyValues {
        primary {
            title1 {
                text
            }
        }
        items {
            text {
                richText
            }
            icon {
                alt
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
        }
    }
    fragment HomepageDataBodyValues on PrismicHomepageDataBodyValues {
        primary {
            title1 {
                text
            }
        }
        items {
            text {
                richText
            }
            icon {
                alt
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
        }
    }
`