import React, {useEffect} from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import useScrollTrigger from "../../hooks/useScrollTrigger";

const Holder = styled.div`
`;

function AnimateOn({children}) {
  const {tl, holderRef, gsap, q} = useScrollTrigger();

  useEffect(() => {
    if (!tl.current) {
      tl.current =
        gsap.timeline({
          scrollTrigger: {
            trigger: holderRef.current,
            start: "top bottom",
            end: "top 80%",
            scrub: 0.25,
          },
        }).from(q(`.animate-on-inner`), {
          yPercent: 20,
          duration: 1,
          ease: 'power2.in'
        })
    }
  })
  return (
    <Holder ref={holderRef}>
      <div className="animate-on-inner">
        {children}
      </div>
    </Holder>
  )
}

export default AnimateOn;