import {createGlobalStyle} from "styled-components"

const GlobalStyle = createGlobalStyle`
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;

    * { box-sizing: border-box; }
  }

  body {
    margin: 0;
    // Use system fonts: https://css-tricks.com/snippets/css/system-font-stack/
    font-family: "Zichtbaar", Helvetica, Arial, sans-serif;
    color: ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.white};
  }

  .container {
    max-width: ${(props) => props.theme.typography.maxScreen}px;
    margin: 0 auto;
    padding: 0 30px;
    @media ( ${props => props.theme.breakpoints.md} ) {
        padding: 0 60px;
    }
  }

  .mono {
    font-family: 'ApproachMono', monospace;
  }

  .subhead {
    font-weight: normal;
    font-family: 'ApproachMono', monospace;
    padding-bottom: 8px;
    font-size: 14px;
    border-bottom: 1px solid;
    margin-bottom: 48px;
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    line-height: 1.25;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  p,
  ol, ul, li,
  code, kbd, pre, samp {
    line-height: 1.5;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: bold;

    a { text-decoration: none; }

    a:hover { text-decoration: none; }
  }

  h1, .h1 { ${(props) => props.theme.fluidType(32, 44)}; }

  h2, .h2 { ${(props) => props.theme.fluidType(24, 32)}; }

  h3, .h3 { font-size: 18px; }

  h4, .h4 { font-size: 18px; }

  h5, .h5 { font-size: 18px; }

  h6, .h6 { font-size: 18px; }

  li {
    margin-top: 0;
    margin-bottom: 0;
  }

  small, p.small { font-size: 14px; }

  code, kbd, pre, samp {
    font-family: 'ApproachMono', monospace;
    white-space: normal;
  }

  ul {
    padding-left: 1rem;
    list-style-type: disc;
  }

  ol {
    padding-left: 1rem;
    list-style-type: decimal;
  }

  video {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }

  // Specific to PrismicRichText component
  .block-img {
    img {
      width: 100%;
      height: auto;
      display: block;
      margin: 3rem 0;
    }
  }

  // Specific to PrismicRichText component
  .embed {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin: 3rem 0;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  em, i { font-style: italic; }

  strong, b { font-weight: bold; }

  blockquote {
    font-weight: bold;
    padding-left: 1rem;
  }

  a { color: inherit; }

  sup, sub {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
  }

  sub { top: 0.4em; }

  label {
    font-size: 14px;
    line-height: 1.2;
    font-weight: normal;
  }

  .text-input,
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="range"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  textarea {
    color: inherit;
    display: block;
    font-family: inherit;
    font-size: 16px;
    padding: 6px 12px;
    margin-top: 22px;
    margin-bottom: 22px;
    border: 1px solid;
    border-radius: 4px;
    line-height: 1.6;
    background-color: transparent;
    -webkit-appearance: none;
    width: 100%;

    &:focus {
      border: 1px ${(props) => props.theme.colors.focus} solid;
    }
  }

  ::placeholder {
    color: inherit;
    opacity: 1;
  }

  .file-input,
  input[type="file"],
  .radio-input,
  input[type="radio"],
  .checkbox-input,
  input[type="checkbox"],
  select {
    font-family: inherit;
  }

  // Select

  select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 2rem;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;
    color: inherit;
  }

  select::-ms-expand {
    display: none;
  }

  .fieldset.select {
    width: 100%;
    border: 1px solid;
    border-radius: 4px;
    font-size: 16px;
    padding: 4px 12px;
    margin-top: 22px;
    margin-bottom: 22px;
    cursor: pointer;
    line-height: 1.1;
    background-color: transparent;
    grid-template-areas: "select";
    display: grid;
    align-items: center;

    &:after {
      content: "";
      width: 8px;
      height: 5px;
      background-color: ${props => props.theme.colors.white};
      clip-path: polygon(100% 0%, 0 0%, 50% 100%);
      justify-self: end;
    }

    select,
    &:after {
      grid-area: select;
    }
  }

  .button,
  button,
  input[type="submit"],
  input[type="button"],
  input[type="reset"] {
    display: inline-block;

    padding: 8px 12px;

    font-family: inherit;
    font-size: 16px;
    line-height: 1.1;
    text-decoration: none;
    white-space: nowrap;
    border: 1px solid;
    border-radius: 4px;

    color: inherit;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      text-decoration: none;
      background-color: ${props => props.theme.colors.yellow};
    }

    &.inverse {
      transition: all 0.2s ease-in-out;
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.black};

      svg {
        path, rect {
          transition: fill 0.2s ease-in-out;
          fill: ${props => props.theme.colors.white};
        }
      }

      &:hover {
        color: ${props => props.theme.colors.black};
        background-color: ${props => props.theme.colors.yellow};

        svg {
          path, rect {
            fill: ${props => props.theme.colors.black};
          }
        }
      }
    }

    &.yellow {
      transition: all 0.2s ease-in-out;
      color: ${props => props.theme.colors.black};
      background-color: ${props => props.theme.colors.yellow};

      svg {
        path, rect {
          transition: fill 0.2s ease-in-out;
          fill: ${props => props.theme.colors.black};
        }
      }

      &:hover {
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.darkGrey};

        svg {
          path, rect {
            fill: ${props => props.theme.colors.white};
          }
        }
      }
    }

    &.link {
      border: none;
      padding: 0;
      background-color: transparent;
    }

    &.with-icon {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;

      svg {
        height: 0.8rem;
        width: auto;
        margin-left: 1rem;
      }
    }

    &.icon {
      border: none;
      padding: 0;
      background-color: transparent;
    }

    &.strip-styles {
      text-align: unset;
      white-space: unset;
      border: unset;
      padding: unset;
      background-color: unset;
      line-height: unset;
      font-size: unset;
    }
  }

  .errorMsg {
    margin: 0;
    color: ${(props) => props.theme.colors.yellow};
    transform: translateY(-8px);
    display: block;
  }
  
  
  .add-bg {
    position: relative;
    z-index: 2;
    &:after {
      content: "";
      background-color: ${props => props.theme.colors.white};
      position: absolute;
      z-index: -1;
      top: -15px;
      left: -15px;
      bottom: -15px;
      right: -15px;
    }
    &.bg-darkGrey:after {
      background-color: ${props => props.theme.colors.darkGrey};
    }
    &.bg-yellow:after {
      background-color: ${props => props.theme.colors.yellow};
    }
  }
`

export default GlobalStyle
