import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import TeamPreview from "../molecules/TeamPreview";
import {graphql} from "gatsby";
import {PrismicRichText} from "@prismicio/react";
import SvgLine from "../organisms/SvgLine";
import DotGrid from "../organisms/DotGrid";
import Container from "../atoms/Container";

const Holder = styled.div`
  overflow: hidden;
`;

const Grid = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0;
  grid-row-gap: calc((100vw - 60px) / 7 * 2);
  padding: calc((100vw - 60px) / 7 * 3) 0;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: repeat(14, 1fr);
    padding: calc((100vw - 120px) / 14) 0;
    grid-row-gap: calc((100vw - 120px) / 14 * 2.5);
    align-items: start;
  }

  .mobile-grid {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 0;

    .dot-grid-square {
      &:nth-child(-n+7) {
        &:before {
          opacity: 0;
        }
      }
    }
  }

  .desktop-grid {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 0;
  }
  .desktop-grid,
  .desktop-left,
  .desktop-right {
    .dot-grid-square {
      &:nth-child(-n+14) {
        &:before,
        &:after {
          opacity: 0;
        }
      }
    }
  }
`;

const Intro = styled.div`
  grid-column: span 7;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-column: 11/15;
    grid-row: 2/3;
  }

  > :first-child { margin-top: 0; }

  > :last-child { margin-bottom: 0; }
`;

const words = ['one', 'two', 'three', 'four'];

function SliceTeam({slice}) {
  const {text, title1} = slice.primary;
  const anchorId = slice.primary['id'];
  return (
    <Holder id={anchorId && anchorId.text}>
      <Container>
        <Grid>
          <Intro className="add-bg">
            <h2>{title1.text}</h2>
            <PrismicRichText field={text.richText} />
          </Intro>
          {slice.items.length > 0 && slice.items.map((item, i) =>
            <TeamPreview key={i} person={item.person.document} position={words[i]} expandLeft={i === 1} />
          )}
          <DotGrid
            className="mobile-grid"
            columns={7}
            rows={50}>
            <SvgLine
              start={[1, 0]}
              midpoints={[[1, 1], [4, 1]]}
              end={[4, 2]}
              hideStart={true} />
            <SvgLine
              start={[6, 1]}
              midpoints={[[5, 1], [5, 8], [7, 8], [7, 11], [5, 11]]}
              end={[5, 15]} />
            <SvgLine
              start={[0, 17]}
              midpoints={[[0, 20], [1, 20], [1, 22], [2, 22], [2, 26]]}
              end={[5, 26]} />
            <SvgLine
              start={[6, 27]}
              midpoints={[[5, 27], [5, 34], [7, 34], [7, 37], [5, 37]]}
              end={[5, 41]} />
            <SvgLine
              start={[0, 37]}
              midpoints={[[0, 40], [1, 40], [1, 42], [2, 42], [2, 46]]}
              end={[5, 46]} />
          </DotGrid>
          <DotGrid
            className="desktop-grid"
            columns={14}
            rows={17}>
            <SvgLine
              start={[0, 0]}
              midpoints={[
                [0, 1],
                [5, 1],
                [5, 4],
                [1, 4],
              ]}
              end={[1, 6]}
              hideStart={true}
              twoArrows/>
            <SvgLine
              start={[6, 0]}
              midpoints={[
                [6, 7],
                [9, 7],
                [9, 10],
                [8, 10],
              ]}
              end={[8, 12]}
              hideStart={true}
              twoArrows/>
          </DotGrid>
          <DotGrid
            className="desktop-left top"
            columns={14}
            rows={17} />
          <DotGrid
            className="desktop-right top"
            columns={14}
            rows={17} />
        </Grid>
      </Container>
    </Holder>
  )
}

SliceTeam.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default SliceTeam;

export const query = graphql`
    fragment PageDataBodyTeam on PrismicPageDataBodyTeam {
        primary {
            id {
                text
            }
            title1 {
                text
            }
            text {
                richText
            }
        }
        items {
            person {
                document {
                    ... on PrismicPerson {
                        id
                        data {
                            name {
                                text
                            }
                            role {
                                text
                            }
                            image {
                                alt
                                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                            }
                            bio {
                                richText
                            }
                        }
                    }
                }
            }
        }
    }
`