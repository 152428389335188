import {Link} from "gatsby";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Logo from '../../assets/svg/logo-horizontal-lockup.inline.svg';
import Hamburger from '../../assets/svg/menu-no-circle.inline.svg';
import classNames from "classnames";
import throttle from 'lodash.throttle';
import SmallNavHolder from "./SmallNavHolder";
import LargeNav from "./LargeNav";
import PropTypes from 'prop-types';

const Holder = styled.header`
  padding: 1.4rem 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
  transition: all 0.25s ease-in-out;
  
  @media( ${props => props.theme.breakpoints.md} ) {
    display: flex;
    align-items: center;
    height: 132px;
  }
  
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => props.theme.colors.yellow};
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }
  
  &.is-light {
    color: ${props => props.theme.colors.white};
    svg path {
      fill: ${props => props.theme.colors.white};
      transition: fill 0.25s ease-in-out;
    }
    .dropdown-menu {
      background-color: ${props => props.theme.colors.darkGrey};
      ul {
        li {
          a {
            &:hover {
              color: ${props => props.theme.colors.darkGrey};
              background-color: ${props => props.theme.colors.white};
            }
          }
        }
      }
    }
    .contact-button:hover {
      background-color: ${props => props.theme.colors.black};
    }
  }
  &.is-light.nav-is-open {
    color: ${props => props.theme.colors.black};
    svg path {
      fill: ${props => props.theme.colors.darkGrey};
    }
  }

  &.is-scrolling {
    color: ${props => props.theme.colors.black};
    svg path {
      fill: ${props => props.theme.colors.black};
    }
    @media( ${props => props.theme.breakpoints.md} ) {
      height: 96px;
    }
    &:after {
      opacity: 1;
    }
  }
`;

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 20;
  width: 100%;

  .logo-holder {
    svg {
      height: 1.5rem;
      width: auto;
      display: block;
      @media ( ${props => props.theme.breakpoints.md} ) {
        height: 2rem;
      }
    }
  }

  > button {
    @media ( ${props => props.theme.breakpoints.md} ) {
      display: none;
    }

    svg {
      height: 1.1rem;
      width: auto;
      display: block;
      overflow: visible;
      transition: transform 0.25s ease-in-out;

      path {
        transition: all 0.25s ease-in-out;
      }
    }

    &.nav-is-open {
      svg {
        transform: translateX(10%);

        path:first-child {
          transform: rotate(45deg) translateY(8%);
          transform-origin: top left;
        }

        path:nth-child(2) {
          opacity: 0;
        }

        path:last-child {
          transform: rotate(-45deg) translateY(-8%);
          transform-origin: bottom left;
        }
      }
    }
  }
`;

function Header({light}) {
  const [open, setOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {

    const onScroll = () => {
      let st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > 30) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    }

    const throttledCount = throttle(onScroll, 100);
    window.addEventListener('scroll', throttledCount);
    return () => window.removeEventListener('scroll', throttledCount);

  });


  const holderClasses = classNames({'is-scrolling': scrolling, 'is-light': light, 'nav-is-open': open})
  const buttonClasses = classNames('icon', {'nav-is-open': open})
  return (
    <Holder className={holderClasses}>
      <SmallNavHolder
        open={open}
        closeHandler={() => setOpen(false)} />
      <Grid className="grid container">
        <Link to="/" className="logo-holder"><Logo /></Link>
        <LargeNav />
        <button className={buttonClasses} onClick={() => setOpen(!open)}><Hamburger /></button>
      </Grid>
    </Holder>
  );
}

Header.propTypes = {
  light: PropTypes.bool,
};

Header.defaultProps = {
  light: false,
};

export default Header;
