import React, {useEffect} from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import {GatsbyImage} from "gatsby-plugin-image";
import {PrismicRichText} from "@prismicio/react";
import useScrollTrigger from "../../hooks/useScrollTrigger";

const Holder = styled.div`
  grid-column: span 7;
  margin-bottom: calc((100vw - 60px) / 7);
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-column: span 3;
    margin-bottom: 0;
    &.value-1 { grid-column: 8/11; }

    &.value-2 { grid-column: 12/15; }

    &.value-3 { grid-column: 2/5; }

    &.value-4 { grid-column: 8/11; }

    &.value-5 { grid-column: 12/15; }
  }

  .gatsby-image-wrapper {
    width: 32px;
  }

  h3 {
    font-size: 24px;
  }

  > :first-child { margin-top: 0; }

  > :last-child { margin-bottom: 0; }
`;

function Value({item, i}) {
  const {tl, holderRef, gsap, st, q} = useScrollTrigger();

  useEffect(() => {
    if (!tl.current) {
      tl.current = st.matchMedia({
        // desktop
        "(min-width: 576px)": function () {
          gsap.timeline({
            scrollTrigger: {
              trigger: holderRef.current,
              start: "top bottom",
              end: "top 70%",
              scrub: 0.25,
            },
          }).from(q(`.gatsby-image-wrapper`), {
            scale: 0,
            transformOrigin: '50% 50%',
            duration: 1,
            ease: 'power2.in'
          }).from(q(`.text-holder`), {
            yPercent: 20,
            duration: 1,
            ease: 'power2.in'
          }, 0)
        },
        // Add more breakpoints if needed.
        // See https://greensock.com/docs/v3/Plugins/ScrollTrigger/static.matchMedia()
      })
    }
  })

  return (
    <Holder ref={holderRef} className={`add-bg bg-darkGrey value-${i + 1}`}>
      <GatsbyImage alt={item.icon.alt || ''} image={item.icon.gatsbyImageData} />
      <div className="text-holder">
        <PrismicRichText field={item.text.richText} />
      </div>
    </Holder>
  )
}

Value.propTypes = {
  i: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
};

export default Value;