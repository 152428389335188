import React from 'react';
import styled from 'styled-components';
import useLockBodyScroll from "../../hooks/useLockBodyScroll";
import {graphql, Link, useStaticQuery} from "gatsby";
import linkResolver from "../../utils/linkResolver";
import {formatPhoneString} from "../../utils/helpers";
import Insta from "../../assets/svg/instagram.inline.svg";
import Twitter from "../../assets/svg/twitter.inline.svg";
import Fb from "../../assets/svg/fb.inline.svg";
import LinkedIn from "../../assets/svg/linkedin.inline.svg";
import DotGrid from "../organisms/DotGrid";
import PropTypes from 'prop-types';

const Holder = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  position: relative;
  overflow: hidden;

  .mobile-grid {
    position: absolute;
    top: 2px;
    left: 0;
    width: calc(100% - 4px);
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      font-family: 'ApproachMono', monospace;
      font-size: 14px;
      margin: 8px 0;
    }
  }

  a {
    text-decoration: none;
  }
`;

const Top = styled.div`
  font-size: 18px;
  padding-right: 12px;

  > :first-child { margin-top: 0; }

  > :last-child { margin-bottom: 0; }

  .about-heading {
    margin-top: 30px;
  }

  .contact-holder {
    margin-top: 30px;

    .button {
      width: 100%;
      text-align: center;
    }
  }
`;

const Bottom = styled.div`
  padding-right: 12px;

  ul {
    li {
      font-size: 12px;
    }
  }
`;

const Socials = styled.ul`
  display: flex;
  margin: 0;

  li {
    margin: 0;
  }

  svg {
    height: 32px;
    width: auto;
    display: block;
    margin-right: 16px;

    rect {
      display: none;
    }
  }
`;

function SmallNav({closeHandler}) {
  const data = useStaticQuery(graphql`
      query SmallNavQuery {
          prismicMenus {
              data {
                  services_menu {
                      link {
                          type
                          uid
                          document {
                              ... on PrismicPage {
                                  id
                                  data {
                                      title {
                                          text
                                      }
                                  }
                              }
                          }
                      }
                  }
                  about_menu {
                      section_id {
                          text
                      }
                      section_title {
                          text
                      }
                  }
              }
          }
          prismicContact {
              data {
                  email {
                      text
                  }
                  facebook {
                      url
                  }
                  instagram {
                      url
                  }
                  linkedin {
                      url
                  }
                  phone {
                      text
                  }
                  twitter {
                      url
                  }
              }
          }
      }
  `)
  const {services_menu, about_menu} = data.prismicMenus.data;
  const {email, phone, facebook, instagram, linkedin, twitter} = data.prismicContact.data;
  useLockBodyScroll();
  return (
    <Holder>
      <DotGrid className="mobile-grid" columns={7} rows={20} />
      <Top className="add-bg bg-yellow">
        <p>Services</p>
        <ul>
          {services_menu.map((item, i) =>
            <li key={i}><Link
              to={linkResolver(item.link)}
              onClick={() => closeHandler()}>{item.link.document.data.title.text}</Link></li>
          )}
        </ul>
        <p className="about-heading"><Link to="/about">About</Link></p>
        <ul>
          {about_menu.map((item, i) =>
            <li key={i}>
              <Link
                to={`/about`}
                onClick={() => closeHandler()}
                state={{scrollToId: item.section_id.text}}>{item.section_title.text}</Link>
            </li>
          )}
        </ul>
        <p className="contact-holder">
          <Link to="/contact"
                onClick={() => closeHandler()}
                className="button">Contact</Link>
        </p>
      </Top>
      <Bottom className="add-bg bg-yellow">
        <ul className="contact-links">
          <li><a href={`mailto:${email.text}`}>info@primarysight.com.au</a></li>
          <li><a href={`tel:${formatPhoneString(phone.text)}`}>{phone.text}</a></li>
        </ul>
        <Socials>
          <li><a target="_blank" rel="noopener noreferrer" href={instagram.url}><Insta /></a></li>
          <li><a target="_blank" rel="noopener noreferrer" href={twitter.url}><Twitter /></a></li>
          <li><a target="_blank" rel="noopener noreferrer" href={facebook.url}><Fb /></a></li>
          <li><a target="_blank" rel="noopener noreferrer" href={linkedin.url}><LinkedIn /></a></li>
        </Socials>
      </Bottom>
    </Holder>
  )
}

SmallNav.propTypes = {
  closeHandler: PropTypes.func.isRequired,
};

export default SmallNav;