import SliceCTA from './SliceCTA'
import SliceFeature from "./SliceFeature";
import SliceHero from "./SliceHero";
import SliceServiceHero from "./SliceServiceHero";
import SliceTeam from "./SliceTeam";
import SliceValues from "./SliceValues";

export const components = {
  cta: SliceCTA,
  feature: SliceFeature,
  hero: SliceHero,
  service_hero: SliceServiceHero,
  team: SliceTeam,
  values: SliceValues,
}