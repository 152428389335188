import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import {CSSTransition} from "react-transition-group";
import SmallNav from "./SmallNav";

const timeout = 1000;

const Holder = styled.div`
  position: absolute;
  z-index: 15;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${props => props.theme.colors.yellow};
  transform-origin: top left;
  padding: 96px 0 27px;
  .inner {
    height: 100%;
  }

  &.nav-holder-appear,
  &.nav-holder-enter {
    transform: scaleY(0);

    .inner {
      opacity: 0;
    }
  }

  &.nav-holder-appear-active,
  &.nav-holder-appear-done,
  &.nav-holder-enter-active,
  &.nav-holder-enter-done {
    transform: scaleY(1);
    transition: transform ${timeout / 2}ms;

    .inner {
      opacity: 1;
      transition: opacity ${timeout / 2}ms ${timeout / 2}ms;
    }
  }

  &.nav-holder-exit {
    transform: scaleY(1);

    .inner {
      opacity: 1;
    }
  }

  &.nav-holder-exit-active {
    transform: scaleY(0);
    transition: transform ${timeout / 2}ms ${timeout / 2}ms;

    .inner {
      opacity: 0;
      transition: opacity ${timeout / 2}ms;
    }
  }
`;

const Inner = styled.div`
  @media ( ${props => props.theme.breakpoints.md} ) {
    height: 100%;
  }
`;

function SmallNavHolder({open, closeHandler}) {
  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      appear
      in={open}
      timeout={timeout}
      classNames="nav-holder"
    >
      <Holder>
        <Inner className="inner container">
          <SmallNav closeHandler={() => closeHandler()}/>
        </Inner>
      </Holder>
    </CSSTransition>
  )
}

SmallNavHolder.propTypes = {
  open: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
};

export default SmallNavHolder;