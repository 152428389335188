import React from "react"
import {Formik, Form, Field, ErrorMessage} from "formik"
import * as Yup from "yup"
import styled from "styled-components";
import Rarr from '../../assets/svg/rarr.inline.svg';
import {graphql, useStaticQuery} from "gatsby";

const Holder = styled.div`
  color: ${props => props.theme.colors.white};

  label { display: none; }

  button {
    width: 100%;
  }

  .two-col {
    @media ( ${props => props.theme.breakpoints.md} ) {
      display: grid;
      grid-gap: 24px;
      grid-template-columns: 1fr 1fr;
      &:last-child {
        grid-template-columns: 7fr 2fr;
        align-items: end;
      }
      .text-input,
      input[type="text"],
      input[type="email"],
      input[type="tel"],
      textarea,
      .fieldset.select {
        margin-top: 0;
      }
      textarea {
        margin-bottom: 0;
      }
    }
  }
`;

const validationSchema = Yup.object().shape({
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("* Name is Required."),
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("* Name is Required."),
  phone: Yup.string()
    .required("* Phone is Required."),
  email: Yup.string()
    .email("* Enter a Valid Email.")
    .required("* Email is Required."),
  company: Yup.string()
    .required("* Phone is Required."),
  lookingFor: Yup.string(),
  message: Yup.string().required("* Message is Required."),
})

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

function ContactForm() {

  const data = useStaticQuery(graphql`
      query ContactFormQuery {
          prismicMenus {
              data {
                  services_menu {
                      link {
                          type
                          uid
                          document {
                              ... on PrismicPage {
                                  id
                                  data {
                                      title {
                                          text
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
  `)

  const {services_menu} = data.prismicMenus.data;

  return (
    <Holder className="add-bg bg-darkGrey">
      <Formik
        initialValues={{lastName: "", firstName: "", phone: "", email: "", company: "", lookingFor: "", message: ""}}
        validationSchema={validationSchema}
        onSubmit={(values, {setSubmitting}) => {
          fetch("/", {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: encode({
              "form-name": "contact-form",
              ...values,
            }),
          })
            .then(() => {
              alert(
                "Thanks! We have received your message and will get back to you ASAP."
              )
              setSubmitting(false)
            })
            .catch((error) => {
              alert(
                "Something went wrong! Could you please try again or email us directly."
              )
              setSubmitting(false)
            })
        }}
      >
        {({isSubmitting}) => (
          <Form
            className="form"
            method="post"
            name="contact-form"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <div className="two-col">
              <div className="fieldset">
                <label htmlFor="lastName" className="label">
                  Name
                </label>
                <Field className="input" type="text" name="lastName" placeholder="Last Name" />
                <ErrorMessage
                  className="errorMsg"
                  name="lastName"
                  component="small"
                />
              </div>
              <div className="fieldset">
                <label htmlFor="firstName" className="label">
                  Name
                </label>
                <Field className="input" type="text" name="firstName" placeholder="First Name" />
                <ErrorMessage
                  className="errorMsg"
                  name="firstName"
                  component="small"
                />
              </div>
            </div>

            <div className="two-col">
              <div className="fieldset">
                <label htmlFor="phone" className="label">
                  Name
                </label>
                <Field className="input" type="text" name="phone" placeholder="Contact Number" />
                <ErrorMessage
                  className="errorMsg"
                  name="phone"
                  component="small"
                />
              </div>
              <div className="fieldset">
                <label htmlFor="email" className="label">
                  Email
                </label>
                <Field className="input" type="text" name="email" placeholder="Email Address" />
                <ErrorMessage
                  className="errorMsg"
                  name="email"
                  component="small"
                />
              </div>
            </div>


            <div className="two-col">
              <div className="fieldset">
                <label htmlFor="company" className="label">
                  Name
                </label>
                <Field className="input" type="text" name="company" placeholder="Company Name" />
                <ErrorMessage
                  className="errorMsg"
                  name="company"
                  component="small"
                />
              </div>

              <label htmlFor="lookingFor" className="label">Colour</label>
              <div className="fieldset select">
                <Field as="select" name="lookingFor">
                  <option value="" disabled>I’m interested in solutions for</option>
                  {services_menu.map((item, i) =>
                    <option key={i} value={item.link.document.data.title.text}>{item.link.document.data.title.text}</option>
                  )}
                </Field>
                <ErrorMessage className="errorMsg" name="lookingFor" component="small" />
              </div>
            </div>

            <div className="two-col">
              <div className="fieldset">
                <label htmlFor="message" className="label">
                  Message
                </label>
                <Field
                  className="input-textarea"
                  name="message"
                  component="textarea"
                  rows="3"
                  placeholder="Your message"
                />
                <ErrorMessage
                  className="errorMsg"
                  name="message"
                  component="small"
                />
              </div>
              <div>
                <button
                  name="submit"
                  type="submit"
                  disabled={isSubmitting}
                  className="button yellow with-icon">Submit<Rarr/></button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Holder>
  )
}

export default ContactForm
