import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import Image from "../atoms/Image";
import Close from '../../assets/svg/close.inline.svg';
import classNames from "classnames";
import {GatsbyImage} from "gatsby-plugin-image";
import {PrismicRichText} from "@prismicio/react";
import useScrollTrigger from "../../hooks/useScrollTrigger";


const Holder = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: -15px;
    right: 15px;
    display: none;
    width: 30px;
    height: 30px;
    z-index: 110;

    path {
      fill: ${props => props.theme.colors.yellow};
    }

    rect {
      stroke: ${props => props.theme.colors.yellow};
      fill: ${props => props.theme.colors.white};
    }
  }
`;

const Inner = styled.div`
  border-radius: 8px;
  transform: translateZ(0);
  border: 1px solid ${props => props.theme.colors.darkGrey};
  position: relative;
  overflow: hidden;
  background-color: ${props => props.theme.colors.white};
`;

const BioHolder = styled.div`
  padding: 30px 35px 40px 40px;
  display: none;

  p {
    font-size: 16px;
  }

  > :first-child { margin-top: 0; }

  > :last-child { margin-bottom: 0; }
`;

const ImageHolder = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  border-bottom: 1px solid ${props => props.theme.colors.darkGrey};
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding-bottom: 82%;
  }

  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: grayscale(1);
  }
`;

const TextHolder = styled.div`
  padding: 34px 20px;

  h3 {
    font-size: 18px;
    margin: 0 0 12px 0;
  }

  .role {
    font-size: 14px;
    margin: 0;
    font-family: 'ApproachMono', monospace;
    border-bottom: 1px solid;
    padding-bottom: 12px;
  }
`;

const PrimaryButton = styled.button`
  grid-column: 1/5;

  &:nth-child(odd) {
    grid-column: 4/8;
  }

  @media ( ${props => props.theme.breakpoints.md} ) {
    &.position-one {
      grid-column: 8/11;
      grid-row: 1/2;
    }

    &.position-two {
      grid-column: 12/15;
      grid-row: 1/2;
    }

    &.position-three {
      grid-column: 1/4;
      grid-row: 2/3;
    }

    &.position-four {
      grid-column: 5/8;
      grid-row: 2/3;
    }
  }
  
  &.is-open {
    position: relative;
    z-index: 100;
    @media ( ${props => props.theme.breakpoints.md} ) {
      ${Holder} {
        width: 233.333%;
        position: absolute;
        top: 0;
        left: 0;

        svg {
          display: block;
        }
      }

      ${Inner} {
        display: grid;
        grid-template-columns: 3fr 4fr;
      }

      ${BioHolder} {
        display: block;
      }
      
      ${ImageHolder} {
        border-right: 1px solid ${props => props.theme.colors.darkGrey};
      }

      &.expandLeft {
        ${Holder} {
          left: auto;
          right: 0;

          svg {
            left: 15px;
            right: auto;
          }
        }

        ${Inner} {
          display: grid;
          grid-template-columns: 4fr 3fr;
        }

        .main-col-holder {
          order: 2;
        }

        ${BioHolder} {
          order: 1;
        }

        ${ImageHolder} {
          border-right: none;
          border-left: 1px solid ${props => props.theme.colors.darkGrey};
        }
      }
    }
  }
`;

const OverlayOuter = styled.div`
  position: fixed;
  z-index: 120;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 27px;
  background-color: rgba(255, 255, 255, 0.8);
  @media ( ${props => props.theme.breakpoints.md} ) {
    display: none;
  }

  button {
    position: absolute;
    top: 12px;
    right: 42px;
    z-index: 10;

    svg {
      width: 30px;
      height: 30px;

      path {
        fill: ${props => props.theme.colors.yellow};
      }

      rect {
        stroke: ${props => props.theme.colors.yellow};
        fill: ${props => props.theme.colors.white};
      }
    }

  }
`;


const OverlayHolder = styled.div`
  position: absolute;
  top: 27px;
  left: 27px;
  right: 27px;
  bottom: 27px;
  border-radius: 8px;
  transform: translateZ(0);
  border: 1px solid;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: ${props => props.theme.colors.white};
  z-index: 1;
`;

function TeamPreview({position, expandLeft, person}) {
  const [open, setOpen] = useState(false);
  const {name, role, bio, image} = person.data;
  const {tl, holderRef, gsap, q} = useScrollTrigger();

  useEffect(() => {
    if (!tl.current) {
      tl.current =
        gsap.timeline({
          scrollTrigger: {
            trigger: holderRef.current,
            start: "top bottom",
            end: "top 70%",
            scrub: 0.25,
          },
        }).from(q(`.team-preview-holder`), {
          yPercent: 20,
          duration: 1,
          ease: 'power2.in'
        })
    }
  })

  const buttonClasses = classNames(
    'team-preview',
    'add-bg',
    'strip-styles',
    `position-${position}`,
    {
      'is-open': open,
      expandLeft
    })

  return (
    <>

      <PrimaryButton ref={holderRef} className={buttonClasses} onClick={() => setOpen(!open)}>
        <Holder className="team-preview-holder">
          <Close />
          <Inner>
            <div className="main-col-holder">
              <ImageHolder>
                <GatsbyImage alt={image.alt || ""} image={image.gatsbyImageData}/>
              </ImageHolder>
              <TextHolder>
                <h3 className="name">{name.text}</h3>
                <p className="role">{role.text}</p>
              </TextHolder>
            </div>
            <BioHolder>
              <PrismicRichText field={bio.richText}/>
            </BioHolder>
          </Inner>
        </Holder>
      </PrimaryButton>

      {open &&
        <OverlayOuter>
          <button className="icon" onClick={() => setOpen(false)}><Close /></button>
          <OverlayHolder>
            <ImageHolder>
              <Image imgName="dinosaur.jpg" />
            </ImageHolder>
            <TextHolder>
              <h3 className="name">Sean Hewat</h3>
              <p className="role">Head of Solutions ANZ </p>
              <p>Sean is an experienced Supply Chain professional with demonstrated history working in the retail
                industry
                focusing on all aspects of the end-to-end supply chain from Asia to store. With a proven track record of
                delivering complex high value projects, on time and within budget. He prides himself on his
                collaborative
                approach and understanding of supply chain processes from supplier to end customer.</p>
              <p>Sean has successfully led end to end projects, across various countries, where innovative solutions
                were
                deployed on time and under budget.</p>
            </TextHolder>
          </OverlayHolder>
        </OverlayOuter>
      }

    </>
  )
}

TeamPreview.propTypes = {
  person: PropTypes.object.isRequired,
  position: PropTypes.string,
  expandLeft: PropTypes.bool,
};
TeamPreview.defaultProps = {
  expandLeft: false,
};

export default TeamPreview;