import React from 'react';
import styled from 'styled-components';
import Insta from "../../assets/svg/instagram.inline.svg";
import Twitter from "../../assets/svg/twitter.inline.svg";
import Fb from "../../assets/svg/fb.inline.svg";
import LinkedIn from "../../assets/svg/linkedin.inline.svg";
import Container from "../atoms/Container";
import PropTypes from "prop-types";
import {PrismicRichText} from "@prismicio/react";
import {formatPhoneString} from "../../utils/helpers";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr;
    border-bottom: 2px solid;
    grid-gap: 16px;
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-template-columns: 5fr 4fr 1fr 4fr;
    grid-gap: 0;
  }

  h1 {
    margin: 0 0 32px 0;
    @media ( ${props => props.theme.breakpoints.md} ) {
      margin: 0;
    }
  }
`;

const ListHolder = styled.div`
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 2px solid;
  display: grid;
  grid-template-columns: 3fr 4fr;
  @media( ${props => props.theme.breakpoints.md} ) {
    border-bottom: none;
    margin-bottom: 0;
  }
  &.office {
    @media( ${props => props.theme.breakpoints.md} ) {
      grid-column: 1/2;
      border-right: 2px solid;
    }
    @media( ${props => props.theme.breakpoints.lg} ) {
      grid-column: auto;
    }
  }
  &.contact {
    @media( ${props => props.theme.breakpoints.lg} ) {
      grid-column: 4/5;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 16px;
  }

  p {
    margin: 0;
    font-size: 12px;
    font-family: 'ApproachMono', monospace;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;

    li {
      font-family: 'ApproachMono', monospace;
      font-size: 12px;
      margin: 8px 0;
      &:first-child {
        margin-top: 0;
      }
    }
  }
`;

const Socials = styled.ul`
  display: flex;
  padding-top: 8px;

  li {
    margin: 0 !important;
  }

  svg {
    height: 32px;
    width: auto;
    display: block;
    margin-right: 8px;

    rect {
      display: none;
    }
    path {
      fill: ${props => props.theme.colors.white};
    }
  }
`;

function ContactDetails(props) {
  return (
    <Container>
      <Grid>
        <h1>Get in touch</h1>
        <ListHolder className="office">
          <h3>Our Office</h3>
          <PrismicRichText field={props.address.richText}/>
        </ListHolder>
        <ListHolder className="contact">
          <h3>Contact</h3>
          <div>
            <ul>
              <li><a href={`mailto:${props.email}`}>{props.email}</a></li>
              <li><a href={`tel:${formatPhoneString(props.phone)}`}>{props.phone}</a></li>
            </ul>
            <Socials>
              <li><a href={props.instagramUrl}><Insta /></a></li>
              <li><a href={props.twitterUrl}><Twitter /></a></li>
              <li><a href={props.facebookUrl}><Fb /></a></li>
              <li><a href={props.linkedinUrl}><LinkedIn /></a></li>
            </Socials>
          </div>
        </ListHolder>
      </Grid>
    </Container>
  )
}

ContactDetails.propTypes = {
  address: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  instagramUrl: PropTypes.string.isRequired,
  twitterUrl: PropTypes.string.isRequired,
  facebookUrl: PropTypes.string.isRequired,
  linkedinUrl: PropTypes.string.isRequired,
};

export default ContactDetails;