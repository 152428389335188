import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import {drawArc} from "../../utils/helpers";
import useScrollTrigger from "../../hooks/useScrollTrigger";
import Chevron from '../../assets/svg/chev-left.inline.svg';
import useWindowSize from "../../hooks/useWindowSize";

const Holder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  svg.line {
    width: 100%;
    height: auto;
    display: block;
    overflow: visible;
    position: relative;
    z-index: 1;

    path {
      stroke-width: 2px;
    }
  }
  
  .arrow {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    svg {
      width: 30px;
      height: 30px;
      display: block;
      path {
        stroke-width: 2px;
        vector-effect: non-scaling-stroke;
      }
    }
  }
`;

const Circle = styled.div`
  position: absolute;
  top: calc(${props => props.top}% - 10px);
  left: calc(${props => props.left}% - 10px);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  will-change: transform;

  .inner {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-left: 5px;
    margin-top: 5px;
    will-change: transform;
  }
`;

function SvgLine({columns, rows, start, midpoints, end, hideEnd, hideStart, debug, twoArrows}) {
  const {tl, holderRef, gsap, q} = useScrollTrigger();
  const lineRef = useRef(null);
  const size = useWindowSize();

  const createTimeline = (lineRef, gsap, q) => {
    return gsap.timeline()
      .to(q(`.circle`), {
        scale: 1.4,
        rotation: 1,
        duration: 1,
        repeat: -1,
        yoyo: true,
        ease: 'none'
      })
      .set(q(`.arrow`), {
        autoAlpha: 1,
      })
      .from(q(`.arrow`), {
        motionPath: {
          path: lineRef.current,
          align: lineRef.current,
          alignOrigin: [0.5, 0.5],
          autoRotate: true,
          start: 1,
          end: 0
        },
        stagger: {
          each: 5,
          repeat: -1
        },
        duration: 10,
        ease: 'none',
      }, 0)

  }

  useEffect(() => {
    if (!tl.current) {
      tl.current = createTimeline(lineRef, gsap, q);
    } else {
      tl.current.kill();
      tl.current = null;
      tl.current = createTimeline(lineRef, gsap, q);
    }
  }, [tl, lineRef, gsap, q, size])

  const arrayOfAllPoints = [[start], midpoints, [end]].flat(1);

  let midString = '';
  if (midpoints) {
    arrayOfAllPoints.forEach((point, i) => {
      if (i !== 0 && i !== arrayOfAllPoints.length - 1) {
        midString = midString.concat(drawArc(point, i, arrayOfAllPoints, debug))
      }
    });
  }

  return (
    <Holder ref={holderRef}>
      <svg className="line" viewBox={`0 0 ${columns} ${rows}`}>
        <path
          ref={lineRef}
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          fill="transparent"
          d={`
          M ${start[0]}, ${start[1]}
          ${midString}
          L ${end[0]}, ${end[1]}
        `} />
      </svg>
      <div className="arrow">
        <Chevron/>
      </div>
      {twoArrows && <div className="arrow">
        <Chevron/>
      </div>}
      {!hideStart && <Circle className="circle" left={start[0] / columns * 100} top={start[1] / (rows + 1) * 100}>
        <div className="inner" />
      </Circle>}
      {!hideEnd && <Circle className="circle" left={end[0] / columns * 100} top={end[1] / (rows + 1) * 100}>
        <div className="inner" />
      </Circle>}
    </Holder>
  )
}

SvgLine.propTypes = {
  columns: PropTypes.number,
  rows: PropTypes.number,
  start: PropTypes.array.isRequired,
  midpoints: PropTypes.array,
  end: PropTypes.array.isRequired,
  hideEnd: PropTypes.bool,
  hideStart: PropTypes.bool,
  debug: PropTypes.bool,
  twoArrows: PropTypes.bool,
};

export default SvgLine;