import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import {graphql} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import DotGrid from "../organisms/DotGrid";
import SvgLine from "../organisms/SvgLine";

const Holder = styled.div`
  padding-top: 96px;
  ${(props) => props.theme.fluidAttribute('padding-bottom', 30, 60)};
  background-color: ${props => props.theme.colors.yellow};
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding-top: 176px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-gap: 0;
    grid-template-columns: 4fr 1fr 9fr;
  }
`;

const ImageHolder = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 80%;
  border-radius: 8px;
  transform: translateZ(0);
  position: relative;
  overflow: hidden;
  @media ( ${props => props.theme.breakpoints.md} ) {
    height: 100%;
    padding-bottom: 0;
    grid-column: 3/4;
  }

  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const TextHolder = styled.div`
  margin-top: calc((100vw - 60px) / 7);
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin-top: 0;
    grid-row: 1/2;
    grid-column: 1/2;
  }

  h1 {
    margin: 0;
  }
`;

function SliceHero({slice}) {
  const {text, image, video} = slice.primary;
  return (
    <Holder>
      <Grid className="container">
        <ImageHolder>
          {!video.url && <GatsbyImage alt={image.alt || ""} image={image.gatsbyImageData} />}
          {video.url && <video autoPlay muted playsInline loop poster={image ? image.url : ""}>
            <source src={video.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>}
        </ImageHolder>
        <TextHolder>
          <DotGrid
            className="mobile-grid"
            columns={7}
            rows={5}
            lineColor={'darkGrey'}>
            <SvgLine
              start={[0, 0]}
              midpoints={[
                [2, 0],
              ]}
              end={[2, 4]} />
            <SvgLine
              start={[7, 1]}
              midpoints={[
                [4, 1],
                [4, 5],
              ]}
              end={[1, 5]} />
          </DotGrid>
          <DotGrid
            className="desktop-grid"
            columns={4}
            rows={4}
            lineColor={'darkGrey'}>
            <SvgLine
              start={[0, 0]}
              midpoints={[
                [2, 0],
              ]}
              end={[2, 3]}/>
            <SvgLine
              start={[0, 4]}
              midpoints={[
                [3, 4],
                [3, 2],
              ]}
              end={[4, 2]}/>
          </DotGrid>
          <h1>{text.text}</h1>
        </TextHolder>
      </Grid>
    </Holder>
  )
}

SliceHero.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default SliceHero;

export const query = graphql`
    fragment HomepageDataBodyHero on PrismicHomepageDataBodyHero {
        primary {
            image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                url(imgixParams: {width: 1500})
            }
            video {
                url
            }
            text {
                text
            }
        }
    }
    fragment PageDataBodyHero on PrismicPageDataBodyHero {
        primary {
            image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                url(imgixParams: {width: 1500})
            }
            video {
                url
            }
            text {
                text
            }
        }
    }
`