export const convertToSlug = (Text) => {
  return (
    "/" +
    Text.toLowerCase()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-")
  )
}

export const formatPhoneString = (Text) => {
  return Text.toLowerCase()
    .replace(/[\s.]+/g, "")
}

export const drawArc = (point, i, arrayOfAllPoints, debug) => {
  if (point[0] === arrayOfAllPoints[i-1][0]) {
    debug && console.log('coming in vertical')
    let arcStartY = point[1] > arrayOfAllPoints[i-1][1] ? point[1] - 0.5 : point[1] + 0.5
    let arcEndX = point[0] < arrayOfAllPoints[i+1][0] ? point[0] + 0.5 : point[0] - 0.5
    let arcStart = `${point[0]} ${arcStartY}`
    let arcEnd = `${arcEndX} ${point[1]}`
    let sweep = 1 //Need to update this to change based on where the curve is coming from
    if(
      (point[1] > arrayOfAllPoints[i-1][1] && // From top
        point[0] < arrayOfAllPoints[i+1][0]) // Going right
      ||
      (point[1] < arrayOfAllPoints[i-1][1] && // From bottom
        point[0] > arrayOfAllPoints[i+1][0]) // Going left
    ) { sweep = 0 }
    let arc = `L ${arcStart} A 0.5 0.5 0 0 ${sweep} ${arcEnd}`

    debug && console.log(arc)
    return arc
  } else if (point[1] === arrayOfAllPoints[i-1][1]) {
    debug && console.log('coming in horizontal')
    let arcStartX = point[0] > arrayOfAllPoints[i-1][0] ? point[0] - 0.5 : point[0] + 0.5
    let arcEndY = point[1] < arrayOfAllPoints[i+1][1] ? point[1] + 0.5 : point[1] - 0.5
    let arcStart = `${arcStartX} ${point[1]}`
    let arcEnd = `${point[0]} ${arcEndY}`
    let sweep = 1 //Need to update this to change based on where the curve is coming from
    if(
      ( point[0] < arrayOfAllPoints[i-1][0] && // From right
        point[1] < arrayOfAllPoints[i+1][1] ) // Going down
      || // or
      ( point[0] > arrayOfAllPoints[i-1][0] && // From left
        point[1] > arrayOfAllPoints[i+1][1] ) // Going up
    ) { sweep = 0 }
    let arc = `L ${arcStart} A 0.5 0.5 0 0 ${sweep} ${arcEnd}`

    debug && console.log(arc)
    return arc
  } else {
    // Something is wrong, just draw a line
    return `L ${point[0]}, ${point[1]} `
  }
}