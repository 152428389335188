import React from 'react';
import styled from 'styled-components';
import ContactForm from "./ContactForm";
import PropTypes from "prop-types";
import {PrismicRichText} from "@prismicio/react";
import DotGrid from "./DotGrid";
import SvgLine from "./SvgLine";
import Container from "../atoms/Container";

const Holder = styled.div`
  overflow: hidden;

  p {
    margin: 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 44px;
  align-items: start;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 4fr 1fr 9fr;
    margin-top: 82px;
    position: relative;
    padding-bottom: calc((100vw - 120px) / 14 * 4.75);
  }

  .desktop-grid {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    bottom: 0;
  }
`;

const TextHolder = styled.div`

`;

const FormHolder = styled.div`
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-column: 3/4;
    .fieldset:first-child {
      input[type="text"] {
        margin-top: 0;
      }
    }
  }
`;

function ContactFormHolder({text}) {
  return (
    <Holder>
      <Container>
        <Grid>
          <TextHolder className="add-bg bg-darkGrey">
            <PrismicRichText field={text.richText} />
          </TextHolder>
          <FormHolder>
            <ContactForm />
          </FormHolder>
          <DotGrid
            className="mobile-grid"
            columns={7}
            rows={7}
            dotColor={'white'}>
            <SvgLine
              start={[0, 1]}
              midpoints={[
                [0, 4],
                [3, 4],
              ]}
              end={[3, 6]} />
            <SvgLine
              start={[6, 0]}
              midpoints={[
                [6, 2],
                [4, 2],
                [4, 6],
              ]}
              end={[7, 6]} />
          </DotGrid>

          <DotGrid
            className="desktop-grid"
            columns={14}
            rows={5}
            dotColor={'white'}>
            <SvgLine
              start={[0, 1]}
              midpoints={[
                [3, 1],
                [3, 3],
                [9, 3],
              ]}
              end={[9, 6]}
              hideEnd={true} />
          </DotGrid>
          <DotGrid
            className="desktop-left bottom"
            columns={14}
            rows={5}
            dotColor={'white'} />
          <DotGrid
            className="desktop-right bottom"
            columns={14}
            rows={5}
            dotColor={'white'} />
        </Grid>
      </Container>
    </Holder>
  )
}

ContactFormHolder.propTypes = {
  text: PropTypes.object.isRequired,
};

export default ContactFormHolder;