export const theme = {
  colors: {
    black: "#000",
    white: "#fff",
    yellow: "#FFC400",
    darkGrey: '#262626',
    lightGrey: '#f0f0f0',
    // Accessibility and forms
    focus: "#4fcd90",
    error: "#d0021b",
  },
  typography: {
    minScreen: 400,
    maxScreen: 1440,
  },
  // https://www.smashingmagazine.com/2016/05/fluid-typography/
  fluidType: (min, max) => {
    return `
      font-size: ${min}px;
      @media screen and (min-width: ${theme.typography.minScreen}px ) {
        font-size: calc( ${min}px + (${max} - ${min})*(100vw - ${theme.typography.minScreen}px)/(${theme.typography.maxScreen} - ${theme.typography.minScreen}) );
      }
      @media screen and (min-width: ${theme.typography.maxScreen}px ) {
        font-size: ${max}px;
      }
      `
  },
  fluidAttribute: (attribute, min, max) => {
    return `
      ${attribute}: ${min}px;
      @media screen and (min-width: ${theme.typography.minScreen}px ) {
        ${attribute}: calc( ${min}px + (${max} - ${min})*(100vw - ${theme.typography.minScreen}px)/(${theme.typography.maxScreen} - ${theme.typography.minScreen}) );
      }
      @media screen and (min-width: ${theme.typography.maxScreen}px ) {
        ${attribute}: ${max}px;
      }
      `
  },
  breakpoints: {
    sm: "min-width: 576px",
    md: "min-width: 768px",
    lg: "min-width: 992px",
    xl: `min-width: 1200px`,
  },
}
